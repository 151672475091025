import { template as template_f58a30fba7bd411e8d31c7cf73fb2ebc } from "@ember/template-compiler";
const FKText = template_f58a30fba7bd411e8d31c7cf73fb2ebc(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
