import { template as template_db377618a23749d4a10d164526832a02 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_db377618a23749d4a10d164526832a02(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
